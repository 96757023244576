import React from "react"

// import "../styles/infoPage.css"
// import "../styles/school_item.css"


// import AdvantagesHeaderSection from "../sections/AdvantagesHeaderSection"
// import AdvantagesContentSection from "../sections/AdvantagesContentSection"
// import ArticleGroupFooterSection from "../sections/ArticleGroupFooterSection"

// import SideNavMenuSection from "../sections/SideNavMenuSection"
// import UserSideNavSection from "../sections/login_signup_side_navs/UserSideNavSection"
// import SchoolSideNavSection from "../sections/login_signup_side_navs/SchoolSideNavSection"

import Layout from "../components/Layout"


const AboutBritishSchools = () => {

  return (
    <Layout>
      {/* <AdvantagesHeaderSection />
      <AdvantagesContentSection />
      <ArticleGroupFooterSection/>
      <SideNavMenuSection />
      <UserSideNavSection />
      <SchoolSideNavSection /> */}
    </Layout>
  )


}

export default AboutBritishSchools
